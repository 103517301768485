import React, { useState, useContext, useEffect } from 'react';
import { GlobalStateData } from '../storage/GlobalDataProvider';
import { GlobalDispatchData } from '../storage/GlobalDataProvider';
import { navigate } from '@reach/router';

const Logout = () => {
  const state = useContext(GlobalStateData);
  const dispatch = useContext(GlobalDispatchData);
  useEffect(() => {
    dispatch({ type: 'APP_LOGOUT', payload: '' });
    navigate('/auth/login');
  }, []);
  return <div>Signing off...</div>;
};
export default Logout;
